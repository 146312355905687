import { Auth0Provider } from "@auth0/auth0-react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "index.css";
import "@mantine/core/styles.css";
import BroadcastPage from "app/pages/BroadcastPage";
import LandingPage from "app/pages/LandingPage";
import HeaderMegaMenu from "components/Layout/Header/Header";
import Footer from "components/Layout/Footer/Footer";
import ViewerLandingPage from "app/pages/ViewerLandingPage";
import AboutUs from "app/pages/AboutUs";
import ProfilePage from "app/pages/ProfilePage";
import PricingPage from "app/pages/PricingPage";
import HtmlHeader from "app/HtmlHeader";
import { MantineProvider, createTheme } from "@mantine/core";

const theme = createTheme({
  fontFamily: "Montserrat",
  fontFamilyMonospace: "Montserrat",
  headings: { fontFamily: "Montserrat" },
});
function App() {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      useRefreshTokens
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: window.location.origin + "/broadcast",
        audience: process.env.REACT_APP_AUTH0_AUDIENCE, // Value in Identifier field for the API being called.
        scope: "openid profile email", // Scope that exists for the API being called. You can create these through the Auth0 Management API or through the Auth0 Dashboard in the Permissions view of your API.
      }}
    >
      <MantineProvider theme={theme} defaultColorScheme="light">
        <HtmlHeader />
        <BrowserRouter>
          <HeaderMegaMenu />
          <div className="App">
            <Routes>
              <Route element={<LandingPage />} path="/" exact />
              <Route element={<BroadcastPage />} path="/broadcast" exact />
              <Route element={<AboutUs />} path="/about-us" />
              <Route element={<PricingPage />} path="/pricing" />
              <Route element={<ProfilePage />} path="/profile-page" />
              <Route
                element={<ViewerLandingPage />}
                path="/viewerlanding/:id"
              />
            </Routes>
          </div>
          <Footer />
        </BrowserRouter>
      </MantineProvider>
    </Auth0Provider>
  );
}

export default App;
