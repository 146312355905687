import React from "react";
import {
  Container,
  Title,
  Text,
  List,
  Box,
  Stack,
  Flex,
} from "@mantine/core";
import standing24 from "assets/standing-24.png";
import standing5 from "assets/standing-5.png";

const AboutUs = () => {
  return (
    <>
      <Container
        fluid
        h={450}
        bg="rgb(87,117,144)"
        p={0} // Remove default padding
      >
        <Flex
          h="100%"
          align="center"
          justify="center"
          pos="relative" // For absolute positioning of text
        >
          <Flex
            w="100%"
            justify="space-between"
            align="center"
            px="md" // Add horizontal padding
          >
            {/* Left image */}
            <Box style={{ flex: "0 0 15vw" }}>
              <img
                src={standing24}
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
            </Box>

            {/* Center text */}
            <Text
              c="white"
              weight={700}
              pos="absolute"
              ta={"center"}
              style={{
                width: "100%",
                letterSpacing: "0.1em",
                fontSize: "10vw",
              }}
            >
              ABOUT US
            </Text>

            {/* Right image */}
            <Box style={{ flex: "0 0 15vw" }}>
              <img
                src={standing5}
                alt=""
                style={{
                  width: "100%",
                  height: "auto",
                  transform: "scaleX(-1)",
                }}
              />
            </Box>
          </Flex>
        </Flex>
      </Container>

      <Container size="lg" mt={"50px"} py="xl">
        <Stack spacing="xl">
          <div>
            <Title order={3}>Our Vision</Title>
            <Text mt="md">
              We envision a world where every church service is accessible to
              everyone, fostering a more connected and unified community. By
              breaking down language barriers, we help churches create an
              environment where all members can engage deeply with the message,
              music, and fellowship.
            </Text>
          </div>

          <div>
            <Title order={3}>What We Do</Title>
            <Text mt="md">
              ChurchLingo provides real-time transcription and translation
              services that capture every word spoken during your services. Our
              technology instantly translates and transcribes sermons, prayers,
              and worship into multiple languages, ensuring that no one is left
              out of the experience.
            </Text>
          </div>

          <div>
            <Title order={3}>Why Choose Us?</Title>
            <List spacing="sm" mt="md">
              <List.Item>
                <Text>
                  <Text span fw={700}>
                    Real-Time Solutions:
                  </Text>{" "}
                  Our service delivers immediate transcription and translation,
                  making it easy for your congregation to follow along in their
                  preferred language.
                </Text>
              </List.Item>
              <List.Item>
                <Text>
                  <Text span fw={700}>
                    Broad Language Options:
                  </Text>{" "}
                  Support for a wide range of languages helps you reach a
                  diverse audience.
                </Text>
              </List.Item>
              <List.Item>
                <Text>
                  <Text span fw={700}>
                    Seamless Integration:
                  </Text>{" "}
                  Our technology integrates smoothly with your existing audio
                  systems.
                </Text>
              </List.Item>
              <List.Item>
                <Text>
                  <Text span fw={700}>
                    Commitment to Accessibility:
                  </Text>{" "}
                  We are dedicated to enhancing the worship experience for all
                  members of your community.
                </Text>
              </List.Item>
            </List>
          </div>

          <div>
            <Title order={3}>Our Story</Title>
            <Text mt="md">
              Founded with a passion for inclusivity and innovation, Our goal is
              to support churches in their mission to welcome and engage every
              individual, regardless of language or hearing ability.
            </Text>
          </div>

          <div>
            <Title order={3}>Get in Touch</Title>
            <Text mt="md">
              We're here to help your church create a more inclusive worship
              experience. Contact us to learn more about our services, schedule
              a demo, or start your free trial.
            </Text>
            <Text fw={700} mt="md">
              Together, let's make worship accessible to all.
            </Text>
          </div>
        </Stack>
      </Container>
    </>
  );
};

export default AboutUs;
