import React from "react";

import {
  Container,
  Flex,
  Text,
  Box,
  Card,
  Stack,
  Button,
  Group,
  rem,
  List,
  Grid,
  ThemeIcon,
} from "@mantine/core";

import { IconCheck } from "@tabler/icons-react";
import standing24 from "assets/standing-24.png";
import standing5 from "assets/standing-5.png";
const plans = [
  {
    name: "SMALL",
    price: "299kr",
    period: "/month",
    purchaseLink: "https://buy.stripe.com/14kbLBcdzcmT2tOdQS",
    description: "For organisations with up to 100 members",
    features: ["10 Hours per Month"],
    highlighted: false,
    buttonText: "Get Started",
  },
  {
    name: "MEDIUM",
    price: "499kr",
    period: "/month",
    purchaseLink: "https://buy.stripe.com/bIY7vlb9vdqXfgA6or",
    description: "For organisations with up to 500 members",
    features: ["20 Hours per month"],
    highlighted: true,
    buttonText: "Get Started",
  },
  {
    name: "LARGE",
    price: "699kr",
    period: "/month",
    purchaseLink: "https://buy.stripe.com/00g16X2CZ4Urd8seUY",
    description: "For large organizations with 1000+ members",
    features: ["30 hours per month"],
    highlighted: false,
    buttonText: "Get Started",
  },
];
function PlanCard({ plan }) {
  return (
    <Card
      padding="xl"
      radius="md"
      withBorder
      style={{
        borderColor: plan.highlighted
          ? "var(--mantine-color-blue-6)"
          : undefined,
        borderWidth: plan.highlighted ? rem(2) : rem(1),
        transform: plan.highlighted ? `translateY(${rem(-8)})` : undefined,
      }}
    >
      <Stack spacing="md">
        {/* Plan Header */}
        <Stack spacing={0} align="center">
          <Text size="xl" fw={700}>
            {plan.name}
          </Text>

          {/* Price */}
          <Group spacing={4}>
            <Text size="xl" fw={700}>
              {plan.price}
            </Text>
            <Text size="sm" c="dimmed">
              {plan.period}
            </Text>
          </Group>

          {/* Description */}
          <Text size="sm" c="dimmed" ta="center" mt="sm">
            {plan.description}
          </Text>
        </Stack>

        {/* Features List */}
        <List
          spacing="sm"
          center
          icon={
            <ThemeIcon color="teal" size={24} radius="xl">
              <IconCheck style={{ width: rem(16), height: rem(16) }} />
            </ThemeIcon>
          }
        >
          {plan.features.map((feature) => (
            <List.Item key={feature}>
              <Text size="sm">{feature}</Text>
            </List.Item>
          ))}
        </List>

        {/* Action Button */}
        <Button
          fullWidth
          component="a"
          href={plan.purchaseLink}
          color={plan.highlighted ? "blue" : "gray"}
          size="md"
        >
          {plan.buttonText}
        </Button>
      </Stack>
    </Card>
  );
}
export default function PricingPage() {

  return (
    <>
      <Container
        fluid
        h={450}
        bg="rgb(87,117,144)"
        p={0} // Remove default padding
      >
        <Flex
          h="100%"
          align="center"
          justify="center"
          pos="relative" // For absolute positioning of text
        >
          <Flex
            w="100%"
            justify="space-between"
            align="center"
            px="md" // Add horizontal padding
          >
            {/* Left image */}
            <Box style={{ flex: "0 0 15vw" }}>
              <img
                src={standing5}
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
            </Box>

            {/* Center text */}
            <Text
              c="white"
              weight={700}
              pos="absolute"
              ta={"center"}
              style={{
                width: "100%",
                letterSpacing: "0.1em",
                fontSize: "10vw",
              }}
            >
              PRICING PLAN
            </Text>

            {/* Right image */}
            <Box style={{ flex: "0 0 15vw" }}>
              <img
                src={standing24}
                alt=""
                style={{
                  width: "100%",
                  height: "auto",
                  transform: "scaleX(-1)",
                }}
              />
            </Box>
          </Flex>
        </Flex>
      </Container>
      <Container size="lg" mt={"50px"} py="xl">
        <Grid gutter="xl">
          {plans.map((plan) => (
            <Grid.Col key={plan.name} span={{ base: 12, sm: 6, md: 4 }}>
              <PlanCard plan={plan} />
            </Grid.Col>
          ))}
        </Grid>
      </Container>
    </>
  );
}
