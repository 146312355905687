import { Helmet } from "react-helmet";
export default function HtmlHeader() {
  return (
    <Helmet>
      <title>Churchlingo - Live Sermon Transcription and Translation</title>
      <meta
        name="description"
        content="Churchlingo allows churches to live transcribe sermons to different languages, making them accessible to a global audience."
      />
      <meta
        property="og:title"
        content="Churchlingo - Live Sermon Transcription and Translation"
      />
      <meta name="viewport" content="width=device-width" />
      <meta
        property="og:description"
        content="Churchlingo allows churches to live transcribe sermons to different languages, making them accessible to a global audience."
      />
      <meta property="og:image" content="http://example.com/logo.jpg" />
      <meta property="og:url" content="http://churchlingo.com" />
    </Helmet>
  );
}
