import React from "react";
import { Container } from "@mantine/core";
const ProfilePage = () => {

  return (


    <Container size="lg" py="xl" >
      Set church name
      <br />
      Delete account
      <br />
      Change Password
      <br />
      Change Tier

    </Container>
  );


};
export default ProfilePage;
