import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Burger,
  Button,
  Divider,
  Drawer,
  Group,
  ScrollArea,
  Text,
  Flex,
  Anchor,
  Avatar,
  UnstyledButton,
  Menu,
  rem,
} from "@mantine/core";

import { IconLogout } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import classes from "components/Layout/Header/HeaderMegaMenu.module.css";
import { Link, useLocation } from "react-router-dom";

export default function HeaderMegaMenu() {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  let location = useLocation();

  if (location.pathname.includes("/viewerlanding")) {
    return;
  }
  return (
    <Box>
      <header className={classes.header}>
        <Group justify="space-between" h="100%">
          <Text component="a" href="/" className={classes.logoText}>
            CHURCHLINGO
          </Text>
          <Group h="100%" gap={0} visibleFrom="md">
            <Anchor
              component={Link}
              to={{ pathname: "/" }}
              className={classes.link}
            >
              HOME{" "}
            </Anchor>
            <Anchor
              component={Link}
              to={{ pathname: "/about-us" }}
              className={classes.link}
            >
              ABOUT
            </Anchor>
            <Anchor
              component={Link}
              to={{ pathname: "/pricing" }}
              className={classes.link}
            >
              PRICING
            </Anchor>
          </Group>

          <Group visibleFrom="md">
            {!isAuthenticated && (
              <>
                <Button variant="default" onClick={() => loginWithRedirect()}>
                  Log in
                </Button>
                <Button
                  onClick={() =>
                    loginWithRedirect({
                      authorizationParams: {
                        screen_hint: "signup",
                      },
                    })
                  }
                >
                  Sign up
                </Button>
              </>
            )}

            {isAuthenticated && (
              <>
                <Button variant="outline" onClick={() => loginWithRedirect()}>
                  Start Broadcast
                </Button>

                <Menu withArrow>
                  <Menu.Target>
                    <UnstyledButton>
                      <Group>
                        <Avatar
                          key={user.nickname}
                          name={user.nickname}
                          variant="filled"
                          color="blue"
                        />
                      </Group>
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Dropdown>
                    {/* <Menu.Item component={Link}
                      to={{ pathname: "/profile-page" }}>
                      Manage Profile
                    </Menu.Item> */}
                    <Menu.Item
                      onClick={() => logout()}
                      color="red"
                      leftSection={
                        <IconLogout
                          style={{ width: rem(14), height: rem(14) }}
                        />
                      }
                    >
                      Log Out
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </>
            )}
          </Group>

          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            hiddenFrom="md"
          />
        </Group>
      </header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Navigation"
        hiddenFrom="md"
        zIndex={1000000}
      >
        <ScrollArea h="calc(100vh - 80px)" mx="-md">
          <Divider my="sm" />

          <a href="/" className={classes.link}>
            Home
          </a>
          <a href="/about-us" className={classes.link}>
            About
          </a>
          <a href="/pricing" className={classes.link}>
            Pricing
          </a>

          <Divider my="sm" />

          <Group justify="center" grow px="md">
            <Flex wrap={"wrap"} justify={"center"} gap={"md"}>
              {isAuthenticated ? (
                <>
                  <Button variant="outline" onClick={() => loginWithRedirect()}>
                    Start Broadcast
                  </Button>
                  <Button
                    variant="outline"
                    color={"red"}
                    onClick={() => logout()}
                  >
                    Log Out
                  </Button>
                </>
              ) : (
                <>
                  <Button variant="default" onClick={() => loginWithRedirect()}>
                    Log In
                  </Button>
                  <Button
                    onClick={() =>
                      loginWithRedirect({
                        authorizationParams: {
                          screen_hint: "signup",
                        },
                      })
                    }
                  >
                    Sign up
                  </Button>
                </>
              )}
            </Flex>
          </Group>
        </ScrollArea>
      </Drawer>
    </Box>
  );
}
