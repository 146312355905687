import React from "react";
import { Group, Button, NumberInput, Checkbox, Text } from "@mantine/core";

export default function ViewerControls({
  onFontSizeChange,
  clearTextInterval,
  setClearTextInterval,
  neverClear,
  toggleNeverClear,
  onLanguageChange,
}) {
  return (
    <Group>
      <Button onClick={onLanguageChange} variant="outline" color="red">
        Change Language
      </Button>
      <Button onClick={() => onFontSizeChange(1)}>Font Size +</Button>
      <Button onClick={() => onFontSizeChange(-1)}>Font Size -</Button>
      <Text size="sm">Clear Text Interval</Text>
      <NumberInput
        value={clearTextInterval / 1000}
        onChange={(value) => setClearTextInterval(value * 1000)}
        min={10}
        max={100}
        step={10}
        suffix="s"
        disabled={neverClear}
        placeholder="Interval (seconds)"
      />
      <Checkbox
        label="Never Clear"
        checked={neverClear}
        onChange={toggleNeverClear}
      />
    </Group>
  );
}
