import React from "react";
import standing1 from "assets/standing-1.png";
import standing2 from "assets/standing-2.png";
import standing13 from "assets/standing-13.png";
import standing14 from "assets/standing-14.png";
import standing15 from "assets/standing-15.png";
import standing18 from "assets/standing-18.png";
import standing24 from "assets/standing-24.png";
import standing5 from "assets/standing-5.png";
import {
  Container,
  Flex,
  Text,
  Box,
  Grid,
  Title,
  List,
  Group,
  Image,
} from "@mantine/core";

const LandingPage = () => {
  return (
    <>
      <Container
        fluid
        h={450}
        bg="rgb(87,117,144)"
        p={0} // Remove default padding
      >
        <Flex
          h="100%"
          align="center"
          justify="center"
          pos="relative" // For absolute positioning of text
        >
          <Flex
            w="100%"
            justify="space-between"
            align="center"
            px="md" // Add horizontal padding
          >
            {/* Left image */}
            <Box style={{ flex: "0 0 15vw" }}>
              <img
                src={standing1}
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
            </Box>

            {/* Center text */}
            <Text
              c="white"
              weight={700}
              pos="absolute"
              ta={"center"}
              style={{
                width: "100%",
                letterSpacing: "0.1em",
                fontSize: "10vw",
              }}
            >
              CHURCHLINGO
            </Text>

            {/* Right image */}
            <Box style={{ flex: "0 0 15vw" }}>
              <img
                src={standing2}
                alt=""
                style={{
                  width: "100%",
                  height: "auto",
                  transform: "scaleX(-1)",
                }}
              />
            </Box>
          </Flex>
        </Flex>
      </Container>
      <Container size="lg" mt={"50px"} py="xl">
        <Grid>
          {/* Left Column */}
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Title order={1} c="#577590" style={{ fontFamily: "Montserrat" }}>
              Real-Time Transcription & Translation for Churches
            </Title>
            <Text
              size="md"
              mt="md"
              style={{
                fontFamily: "Montserrat",
                letterSpacing: "1px",
                lineHeight: 1.6,
              }}
            >
              Break down language barriers in your church with Churchlingo. Our
              service provides real-time transcription and translation of
              sermons, prayers, and worship, allowing everyone to connect with
              the message in their preferred language.
            </Text>
          </Grid.Col>

          {/* Right Column */}
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Title order={1} c="#577590" style={{ fontFamily: "Montserrat" }}>
              Key Features
            </Title>
            <List
              spacing="md"
              mt="md"
              style={{ fontFamily: "Montserrat" }}
              styles={{
                item: {
                  fontSize: "16px",
                  letterSpacing: "1px",
                  lineHeight: 1.6,
                },
              }}
            >
              <List.Item>
                <Text>
                  <Text component="span" fw={700}>
                    Instant Transcription & Translation:
                  </Text>{" "}
                  Capture spoken words and translate them into multiple
                  languages instantly.
                </Text>
              </List.Item>
              <List.Item>
                <Text>
                  <Text component="span" fw={700}>
                    Inclusive Worship:
                  </Text>{" "}
                  Support non-native speakers and the hearing impaired with live
                  transcriptions and translations on screens or personal
                  devices.
                </Text>
              </List.Item>
              <List.Item>
                <Text>
                  <Text component="span" fw={700}>
                    Easy Integration:
                  </Text>{" "}
                  Seamlessly connects with your existing audio and visual
                  systems.
                </Text>
              </List.Item>
              <List.Item>
                <Text>
                  <Text component="span" fw={700}>
                    Broad Language Support:
                  </Text>{" "}
                  Choose from a variety of languages to meet the needs of your
                  diverse congregation.
                </Text>
              </List.Item>
            </List>
          </Grid.Col>
        </Grid>
      </Container>

      <Container fluid py={"xl"}>
        <Group justify="space-between">
          <Image src={standing13} alt="" height={"250vh"} w="16.6%" />
          <Image src={standing14} alt="" height={"250vh"} w="16.6%" />
          <Image src={standing15} alt="" height={"250vh"} w="16.6%" />
          <Image src={standing18} alt="" height={"250vh"} w="16.6%" />
          <Image src={standing24} alt="" height={"250vh"} w="16.6%" />
          <Image src={standing5} alt="" height={"250vh"} w="16.6%" />
        </Group>
      </Container>
    </>
  );
};

export default LandingPage;
