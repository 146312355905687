import {
  Paper,
  Stack,
  Group,
  Text,
  Divider,
  Alert,
  Title,
} from "@mantine/core";
import {
  IconCheck,
  IconAlertTriangleFilled,
  IconMicrophone,
  IconPlayerPlayFilled,
} from "@tabler/icons-react";

const InstructionsCard = () => (
  <Paper shadow="sm" p="md" withBorder>
    <Title order={3} className="title is-5 mb-4">
      Getting Started
    </Title>
    <Stack gap={"xl"} mt="md" spacing="md">
      <Group>
        <IconCheck size={20} color="black" />
        <div>
          <Text size="sm" weight={500}>
            Browser Permission
          </Text>
          <Text size="xs" c="dimmed">
            Approve browser to access Input device
          </Text>
        </div>
      </Group>

      <Group>
        <IconMicrophone size={20} color="black" />
        <div>
          <Text size="sm" weight={500}>
            Select Device
          </Text>
          <Text size="xs" c="dimmed">
            Choose the input for the audio under Device List
          </Text>
        </div>
      </Group>

      <Group>
        <IconPlayerPlayFilled size={20} color="black" />
        <div>
          <Text size="sm" weight={500}>
            Start Broadcasting
          </Text>
          <Text size="xs" color="dimmed">
            Press start recording to begin your broadcast
          </Text>
        </div>
      </Group>
    </Stack>

    <Divider my="lg" />

    <Alert
      color="yellow"
      radius="md"
      icon={<IconAlertTriangleFilled size={18} />}
      withCloseButton={false}
    >
      Do not close this tab during stream, as it will stop the transcription to
      users as well
    </Alert>
  </Paper>
);

export default InstructionsCard;
