import { Progress, Stack, Text, Group, Select, Loader } from "@mantine/core";
import { useMemo, useCallback, useEffect, useRef, useState } from "react";

export const DeviceList = ({ streamLive, microphone, setMicrophone }) => {
  // Replace the ref with state for audio devices
  const [audioDevices, setAudioDevices] = useState([]);
  const [gotDevices, setGotDevices] = useState(false);
  const [volume, setVolume] = useState(0);

  // Audio context refs
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const sourceRef = useRef(null);
  const streamRef = useRef(null);
  const animationFrameRef = useRef(null);

  const startVolumeMeter = useCallback(async (deviceId) => {
    try {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: deviceId ? { exact: deviceId } : undefined },
      });
      streamRef.current = stream;
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
      sourceRef.current.connect(analyserRef.current);
      analyserRef.current.fftSize = 256;
      const bufferLength = analyserRef.current.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const updateVolume = () => {
        analyserRef.current.getByteFrequencyData(dataArray);
        const average = dataArray.reduce((acc, val) => acc + val, 0) / bufferLength;
        const normalizedVolume = Math.min(100, (average / 128) * 100);
        setVolume(Math.round(normalizedVolume));
        animationFrameRef.current = requestAnimationFrame(updateVolume);
      };

      updateVolume();
    } catch (err) {
      console.error("Error starting volume meter:", err);
    }
  }, []); // Empty dependency array since it only uses refs

  // Stop the volume meter
  const stopVolumeMeter = useCallback(() => {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
    if (sourceRef.current) {
      sourceRef.current.disconnect();
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
    setVolume(0);
  }, []);

  // First useEffect - add missing dependencies
  useEffect(() => {
    if (!navigator.mediaDevices?.enumerateDevices) {
      console.log("enumerateDevices() not supported.");
    } else {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {
          navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
              const newAudioDevices = devices.filter((device) => device.kind === "audioinput");
              setAudioDevices(newAudioDevices);
              if (newAudioDevices.length > 0) {
                setMicrophone(newAudioDevices[0].deviceId);
                startVolumeMeter(newAudioDevices[0].deviceId);
              }
            })
            .then(() => {
              setGotDevices(true);
            })
            .catch((err) => {
              console.error(`${err.name}: ${err.message}`);
              setGotDevices(true);
            });
        })
        .catch((err) => {
          console.error(err);
          setGotDevices(true);
          setAudioDevices([]); // No audio devices found
        });
    }

    return () => {
      stopVolumeMeter();
    };
  }, [setMicrophone, startVolumeMeter, stopVolumeMeter]); // Add missing dependencies

  // Second useEffect - devicechange listener
  useEffect(() => {
    const handleDevicesChanged = () => {
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          const newAudioDevices = devices.filter(device => device.kind === "audioinput");
          setAudioDevices(newAudioDevices);
          if (!newAudioDevices.find(d => d.deviceId === microphone)) {
            setMicrophone(newAudioDevices[0]?.deviceId);
          }
        });
    };

    navigator.mediaDevices.addEventListener('devicechange', handleDevicesChanged);
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', handleDevicesChanged);
    };
  }, [microphone, setMicrophone]); // Add missing dependency

  // Correct the handleDeviceChange function
  const handleDeviceChange = (newDeviceData) => {
    console.log(newDeviceData)
    setMicrophone(newDeviceData.value); // Remove .value since the value is passed directly
    stopVolumeMeter();
    startVolumeMeter(newDeviceData.value);
  };
  // Fix useMemo to use state instead of ref
  const deviceOptions = useMemo(() =>
    audioDevices.map((device) => ({
      value: device.deviceId,
      label: device.label || `Device ${device.deviceId}`,
    })), [audioDevices]); // Use audioDevices state instead of ref
  return (
    <Stack spacing="md">
      <Text size="lg" weight={500}>
        Device List
      </Text>

      {gotDevices ? (
        audioDevices.length > 0 ? (
          <>
            <Group>
              <Select
                label="Select a device"
                value={microphone ? microphone : null}
                onChange={(_value, option) => handleDeviceChange(option)}
                data={deviceOptions}  // Pass the device options to the Select
                allowDeselect={false}
                disabled={streamLive ? true : false}
              />
            </Group>

            <Text>Input Volume: {volume}%</Text>
            <Progress value={volume} color="blue" size="lg" radius="xl" />
          </>
        ) : (
          <Text c="red">No Microphone found</Text>
        )
      ) : (
        <Loader size="sm" color="blue" />
      )}
    </Stack>
  );
};
