import React, { useState, useRef } from "react";
import { Stack, Container, Center } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import ViewerControls from "features/ViewerDashboard/components/ViewerControls";
import StreamMessageViewer from "features/ViewerDashboard/components/StreamMessageViewer";
import LanguageSelector from "features/ViewerDashboard/components/LanguageSelector";
import useSocketTranslatedLanguage from "features/ViewerDashboard/hooks/getTranslatedText";
import {
  DEFAULT_FONT_SIZE,
  MIN_CLEAR_INTERVAL,
  MAX_CLEAR_INTERVAL,
} from "constants";
import { useParams } from "react-router-dom";

export default function ViewerLandingPage() {
  const { id: roomId } = useParams();
  const [language, setLanguage] = useState(null);
  const [chosenLanguage, setChosenLanguage] = useState(null);
  const [fontSize, setFontSize] = useState(DEFAULT_FONT_SIZE);
  const [clearTextInterval, setClearTextInterval] = useState(
    MIN_CLEAR_INTERVAL * 1000,
  );
  const [message, setMessage] = useState("");
  const [neverClear, setNeverClear] = useState(true);

  const totalMsg = useRef("");
  const textareaRef = useRef(null);
  const { height } = useViewportSize();

  const updateMessage = (newMessage) => {
    totalMsg.current += (totalMsg.current ? "\n" : "") + newMessage;
    setMessage(totalMsg.current.trim());
  };

  const onLanguageSelect = () => {
    setChosenLanguage(language);
    totalMsg.current = "";
  };

  const onLanguageChange = () => {
    setChosenLanguage(null);
    totalMsg.current = "";
    setMessage("");
  };

  const onFontSizeChange = (delta) => setFontSize((prev) => prev + delta);

  const toggleNeverClear = () => {
    setNeverClear((prev) => !prev);
    if (!neverClear) setClearTextInterval(MAX_CLEAR_INTERVAL * 1000);
  };

  useSocketTranslatedLanguage(roomId, language, updateMessage);
  return (
    <>
      <Container fluid h={height} pt={100}>
        {chosenLanguage ? (
          <Center>
            <Stack>
              <ViewerControls
                onFontSizeChange={onFontSizeChange}
                clearTextInterval={clearTextInterval}
                setClearTextInterval={setClearTextInterval}
                neverClear={neverClear}
                toggleNeverClear={toggleNeverClear}
                onLanguageChange={onLanguageChange}
              />
              <StreamMessageViewer
                message={message}
                fontSize={fontSize}
                textareaRef={textareaRef}
              />
            </Stack>
          </Center>
        ) : (
          <LanguageSelector
            language={language}
            setLanguage={setLanguage}
            onSelect={onLanguageSelect}
          />
        )}
      </Container>
    </>
  );
}
