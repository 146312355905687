import React, { useEffect } from "react";
import { Stack, Textarea } from "@mantine/core";

export default function StreamMessageViewer({
  message,
  fontSize,
  textareaRef,
}) {
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [message]);

  return (
    <Stack>
      <Textarea
        ref={textareaRef}
        value={message}
        readOnly
        autosize
        minRows={20}
        maxRows={20}
        styles={{ input: { fontSize: `${fontSize}px` } }}
      />
    </Stack>
  );
}
