import React from "react";
import { Stack, Text, Select, Button } from "@mantine/core";
import { LANGUAGE_OPTIONS } from "constants";
const LanguageSelector = ({ language, setLanguage, onSelect }) => (
  <Stack justify="center" align="center">
    <Text size="xl">Select Language</Text>
    <Select
      data={LANGUAGE_OPTIONS}
      value={language}
      onChange={setLanguage}
      placeholder="Select a language"
    />
    <Button
      onClick={onSelect}
      disabled={!language}
      size="md"
      variant="filled"
      color="blue"
    >
      Continue
    </Button>
  </Stack>
);

export default LanguageSelector;
