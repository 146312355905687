import { useEffect } from "react";
import { socket } from "app/socket";

export default function useSocketTranslatedLanguage(
  roomId,
  language,
  updateMessage,
) {
  useEffect(() => {
    if (!roomId || !language) return;

    // Join the socket room
    socket.emit("join", { room: roomId, language });
    const handleTranslation = (data) => {
      console.log(data);
      const languageIndex = data?.privMap?.privKeys?.indexOf(language);
      if (languageIndex === -1) return;
      const translatedMessage = data.privMap.privValues[languageIndex];
      if (translatedMessage) updateMessage(translatedMessage);
    };
    socket.on("translated", handleTranslation);

    // Cleanup: Leave the socket room on unmount or language change
    return () => {
      socket.emit("leave", { room: roomId });
      socket.off("translated", handleTranslation);
    };
  }, [roomId, language, updateMessage]);
}
