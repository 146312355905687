import { Anchor, Container, Group, Text } from "@mantine/core";
import { MantineLogo } from "@mantinex/mantine-logo";
import classes from "components/Layout/Footer/Footer.module.css";

const links = [{ link: "mailto: support@churchlingo.se", label: "Contact" }];

export default function Footer() {
  const items = links.map((link) => (
    <Anchor
      component="a"
      c="dimmed"
      key={link.label}
      href={link.link}
      size="sm"
    >
      {link.label}
    </Anchor>
  ));

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <MantineLogo size={28} />
        <Text>
          {" "}
          <strong>© 2024 Akhil Svanerud</strong> All Rights Reserved
        </Text>
        <Group className={classes.links}>{items}</Group>
      </Container>
    </div>
  );
}
