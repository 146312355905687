import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { socket } from "app/socket";
import {
  SpeechTranslationConfig,
  AudioConfig,
  TranslationRecognizer,
  ResultReason,
} from "microsoft-cognitiveservices-speech-sdk";
import { DeviceList } from "features/BroadcasterDashboard/components/InputDeviceList";
import { IconBroadcast } from "@tabler/icons-react";
import {
  addBroadcasterToDB,
  getSpeechServiceToken,
  getUserSubscription,
  startStream,
  stopStream,
} from "api/BroadcastApi";
import { Container, Paper, Stack, Button, Badge, Grid } from "@mantine/core";

import InstructionsCard from "features/BroadcasterDashboard/components/InstructionsCard";
import BroadcastTimer from "features/BroadcasterDashboard/components/BroadcastTimer";
import { useViewportSize } from "@mantine/hooks";

const BroadcastPage = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  const navigate = useNavigate();

  const [recognizer, setRecognizer] = useState(null);
  const [streamLive, setStreamLive] = useState(false);
  const [isActiveSub, setIsActiveSub] = useState(false);
  const retryCountRef = useRef(null);
  const [microphone, setMicrophone] = useState(null);
  const autoStopTimeoutRef = useRef(null);
  const lastActivityTimeRef = useRef(null);

  const { height } = useViewportSize();
  // Constants for timeout durations (in milliseconds)
  const MAX_STREAM_DURATION = 2 * 60 * 60 * 1000; // 2 hours
  const INACTIVITY_TIMEOUT = 30 * 60 * 1000; // 30 minutes
  const cleanupRecognizer = async (recognizerInstance) => {
    try {
      if (recognizerInstance) {
        await recognizerInstance.stopContinuousRecognitionAsync();
        recognizerInstance.close();
        const token = await getAccessTokenSilently();
        await stopStream(user?.nickname, token);
        setRecognizer(null);
      }
      // Clear any existing timeouts
      if (autoStopTimeoutRef.current) {
        clearTimeout(autoStopTimeoutRef.current);
        autoStopTimeoutRef.current = null;
      }
    } catch (error) {
      console.error("Error during recognizer cleanup:", error);
      setRecognizer(null);
    }
  };

  const checkInactivity = () => {
    if (!streamLive || !lastActivityTimeRef.current) return;

    const currentTime = Date.now();
    const inactivityDuration = currentTime - lastActivityTimeRef.current;

    if (inactivityDuration >= INACTIVITY_TIMEOUT) {
      console.log("Stream stopped due to inactivity");
      cleanupRecognizer(recognizer);
    }
  };

  const createNewRecognizer = async () => {
    try {
      const authToken = await getAccessTokenSilently();
      const speechServiceToken = await getSpeechServiceToken(authToken);
      console.log(microphone);
      const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
      const speechConfig = SpeechTranslationConfig.fromAuthorizationToken(
        speechServiceToken.data.access_token,
        speechServiceToken.data.region
      );

      speechConfig.speechRecognitionLanguage = "sv-SE";
      speechConfig.addTargetLanguage("en");
      speechConfig.addTargetLanguage("fr");
      speechConfig.addTargetLanguage("it");

      const newRecognizer = new TranslationRecognizer(
        speechConfig,
        audioConfig
      );

      newRecognizer.recognizing = (s, e) => {
        retryCountRef.current = 0;
        lastActivityTimeRef.current = Date.now(); // Update last activity time
        console.log(`Recognizing text: ${e.result.text}`);
      };

      newRecognizer.recognized = (s, e) => {
        if (e.result.reason === ResultReason.TranslatedSpeech) {
          lastActivityTimeRef.current = Date.now(); // Update last activity time
          console.log(e.result.translations);
          socket.emit(
            "translationResults",
            e.result.translations,
            user.nickname
          );
        }
      };

      newRecognizer.canceled = async (s, e) => {
        console.error(`Recognition canceled: ${e.errorDetails}`);
        if (retryCountRef.current < 3) {
          console.log(`Retry attempt ${retryCountRef.current + 1}...`);
          retryCountRef.current += 1;

          await cleanupRecognizer(newRecognizer);
          try {
            const recreatedRecognizer = await createNewRecognizer();
            if (recreatedRecognizer) {
              setRecognizer(recreatedRecognizer);
              recreatedRecognizer.startContinuousRecognitionAsync();
            }
          } catch (recreateError) {
            console.error("Failed to recreate recognizer:", recreateError);
          }
        } else {
          console.error("Max retries reached. No further attempts.");
          retryCountRef.current = 0;
          await cleanupRecognizer(newRecognizer);
        }
      };

      newRecognizer.sessionStopped = async (s, e) => {
        console.log("Session stopped.");
        await cleanupRecognizer(newRecognizer);
      };

      newRecognizer.sessionStarted = async (s, e) => {
        console.log("Session started.");
        const token = await getAccessTokenSilently();
        await startStream(user.nickname, token);
        setStreamLive(true);
        lastActivityTimeRef.current = Date.now();

        // Set up auto-stop timeout
        autoStopTimeoutRef.current = setTimeout(() => {
          console.log("Stream stopped due to maximum duration reached");
          cleanupRecognizer(newRecognizer);
        }, MAX_STREAM_DURATION);

        // Set up inactivity checker
        const inactivityChecker = setInterval(checkInactivity, 60000); // Check every minute
        return () => clearInterval(inactivityChecker);
      };

      return newRecognizer;
    } catch (error) {
      console.error("Error creating new recognizer:", error);
      return null;
    }
  };

  useEffect(() => {
    const initializeUser = async () => {
      if (!isAuthenticated || !user) return;

      try {
        const token = await getAccessTokenSilently();
        const response = await getUserSubscription(
          user.stripe_customer_id,
          token
        );

        if (!["active", "trialing"].includes(response.data.data)) {
          navigate("/pricing");
          return;
        }

        setIsActiveSub(true);
        await addBroadcasterToDB(user.nickname, token);
        socket.emit("join_broadcast_room", user.nickname);
      } catch (error) {
        console.error("Error initializing user:", error);
        setIsActiveSub(false);
      }
    };

    initializeUser();
  }, [isAuthenticated, user, getAccessTokenSilently, navigate]);

  const startRecognition = async () => {
    try {
      if (!recognizer) {
        const newRecognizer = await createNewRecognizer();
        if (!newRecognizer) {
          throw new Error("Failed to create new recognizer");
        }
        setRecognizer(newRecognizer);
        await newRecognizer.startContinuousRecognitionAsync();
      } else {
        await recognizer.startContinuousRecognitionAsync();
      }

      setStreamLive(true);
      console.log("Translation Started");
    } catch (error) {
      console.error("Error in startRecognition:", error);
      await cleanupRecognizer(recognizer);
    }
  };

  const stopRecognition = async () => {
    try {
      if (recognizer) {
        await recognizer.stopContinuousRecognitionAsync();
        setStreamLive(false);
        console.log("Translation stopped.");
      }
    } catch (error) {
      console.error("Error in stopRecognition:", error);
      await cleanupRecognizer(recognizer);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated || !isActiveSub) {
    return null;
  }

  return (
    <Container h={height} size="lg" py="xl">
      <Stack spacing="xl">
        <InstructionsCard />
        <Grid>
          <Grid.Col span={6}>
            <Paper shadow="xs" p="xl" radius="md" withBorder h="100%">
              <DeviceList
                streamLive={streamLive}
                microphone={microphone}
                setMicrophone={setMicrophone}
              />
            </Paper>
          </Grid.Col>
          <Grid.Col span={6}>
            <Paper shadow="sm" p="xl" withBorder h="100%">
              <Stack align="center" spacing="xl">
                {!streamLive ? (
                  <Button onClick={startRecognition}>
                    <span className="icon">
                      <i className="fas fa-microphone"></i>
                    </span>
                    <span>START RECORDING</span>
                  </Button>
                ) : (
                  <Button color="red" onClick={stopRecognition}>
                    <span className="icon">
                      <i className="fas fa-stop-circle"></i>
                    </span>
                    <span>STOP RECORDING</span>
                  </Button>
                )}
                <audio id="audio" autoPlay muted />
                <Button
                  component="a"
                  href={`/viewerlanding/${user.nickname}`}
                  target="_blank"
                  color="blue"
                  variant="light"
                >
                  JOIN STREAM
                </Button>

                {streamLive && (
                  <>
                    <Badge color="red" variant="filled" size="lg">
                      <IconBroadcast size={18} /> LIVE
                    </Badge>
                    <BroadcastTimer isActive={streamLive} />
                  </>
                )}
              </Stack>
            </Paper>
          </Grid.Col>
        </Grid>
      </Stack>
    </Container>
  );
};

export default BroadcastPage;
